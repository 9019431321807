import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import * as filesApi from 'library/api/files';
import { getKidsByGroupId } from 'library/api/kids';
import { findSignatureDataForUsers } from 'library/api/signature';
import { getGroupMembers } from 'library/common/commonActions/groupActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import MultiUserSignaturePopupHeader from './MultiUserSignaturePopupHeader';
import Popup from 'library/common/commonComponents/Popups/Popup';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { exportSignatureListPDF } from 'library/utilities/signature';
import store from 'main/store/configureStore';

import GenericTwoButtonPopup from '../GenericTwoButtonPopup';
import styles from './MultiUserSignaturePopup.module.scss';

export default function MultiUserSignaturePopup({
  isOpened,
  activeKita,
  closePopup,
  title,
  group,
  files,
  currentUser,
}) {
  const { t } = useTranslation();

  const [headerSelectBoxState, setHeaderSelectBoxState] = useState(0);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);
  const [searchBoxContent, setSearchBoxContent] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectBoxUsers, setSelectBoxUsers] = useState([]);
  const [clickedUser, setClickedUser] = useState(null);
  const [signatureUserCount, setSignatureUserCount] = useState(0);
  const [noneSignatureUserCount, setNoneSignatureUserCount] = useState(0);
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  useEffect(() => {
    loadInitialUsers();
  }, []);

  useEffect(() => {
    if (initialUsersLoaded === true) {
      setFilteredUsers(loadUsersByTextSearchState(selectBoxUsers));
    }
  }, [searchBoxContent]);

  useEffect(() => {
    loadUsersBySelectboxState();
  }, [headerSelectBoxState]);

  function loadUsersBySelectboxState() {
    if (initialUsersLoaded === true) {
      if (headerSelectBoxState === 0) {
        setFilteredUsers(loadUsersByTextSearchState(initialUsers));
        setSelectBoxUsers(initialUsers);
      } else if (headerSelectBoxState === 1) {
        const signed = initialUsers.filter(u => u.hasSignature === true);
        setFilteredUsers(loadUsersByTextSearchState(signed));
        setSelectBoxUsers(signed);
      } else if (headerSelectBoxState === 2) {
        const notSigned = initialUsers.filter(u => u.hasSignature === false);
        setFilteredUsers(loadUsersByTextSearchState(notSigned));
        setSelectBoxUsers(notSigned);
      }
    }
  }

  function loadUsersByTextSearchState(users) {
    const textSearchFilter = users.filter(function(n) {
      return (
        `${n.firstName} ${n.lastName}`.toLowerCase().includes(searchBoxContent.toLowerCase()) ||
        `${n.lastName} ${n.firstName}`
          .toLowerCase()
          .includes(searchBoxContent.toLowerCase() || searchBoxContent === '')
      );
    });
    return textSearchFilter;
  }

  async function loadInitialUsers() {
    const filters = [{ filterName: 'search' }];
    const allGroupMembers = await getGroupMembers(group.groupId, filters);
    let allGroupUsers = allGroupMembers.data.content.map(gm => gm.user);
    if (!files || files.length !== 1) {
      return;
    }
    const fileId = files[0].id;

    const res = await findSignatureDataForUsers(fileId, allGroupUsers.map(u => u.id));
    const signatureData = res.data;
    let signedUserCount = 0;
    allGroupUsers = allGroupUsers.map(u => {
      u.hasSignature = u.id in signatureData;

      if (u.hasSignature) {
        signedUserCount += 1;
        const date = new Date(signatureData[u.id].created_at);
        u.signatureDate =
          date.toLocaleDateString('de-DE') + ' ' + date.toLocaleTimeString('de-DE');

        u.signedFile = signatureData[u.id].publicFileId;
      }

      return u;
    });

    setSignatureUserCount(signedUserCount);
    setNoneSignatureUserCount(allGroupUsers.length - signedUserCount);
    setInitialUsers(allGroupUsers);
    setFilteredUsers(allGroupUsers);
    setSelectBoxUsers(allGroupUsers);
    setInitialUsersLoaded(true);
  }

  async function openReadMode() {
    if (clickedUser.signedFile !== -1) {
      filesApi
        .downloadFile(clickedUser.signedFile)
        .then(data => {
          const fileURL = window.URL.createObjectURL(data.data);
          window.open(fileURL);
        })
        .catch(() =>
          store.dispatch(
            showBottomNotification(t('MultiUserSignature.The file could not be opened'), {
              isFail: true,
            }),
          ),
        );
    } else {
      store.dispatch(
        showBottomNotification(t('MultiUserSignature.The file was deleted'), { isFail: true }),
      );
    }

    setClickedUser(null);
  }

  function openEditMode() {
    if (clickedUser.signedFile !== -1) {
      // eslint-disable-next-line max-len
      window.location = `/signature/${
        clickedUser.signedFile
      }/${group.groupId}?referrer=${encodeURIComponent(window.location.href)}&overwrite=${
        files[0].id
      }&user=${clickedUser.id}`;
    } else {
      store.dispatch(
        showBottomNotification(t('MultiUserSignature.The file was deleted'), { isFail: true }),
      );
    }

    setClickedUser(null);
  }

  const handleExportPDFForSignatureList = async () => {
    setIsPrintLoading(true);
    const res = await getKidsByGroupId(group.groupId);
    await exportSignatureListPDF(group, activeKita.kitaName, initialUsers, res.data, files[0].fileId, t);
    setIsPrintLoading(false);
  };

  return (
    <Popup isOpened={isOpened} closePopup={closePopup} header={title}>
      {clickedUser != null && (
        <GenericTwoButtonPopup
          isOpened={clickedUser != null}
          headerText='foo'
          closePopup={() => setClickedUser(null)}
          button1Text={t('MultiUserSignature.Read mode')}
          button1Action={() => openReadMode()}
          button2Text={t('MultiUserSignature.Add own signature')}
          button2Action={() => openEditMode()}
          text={t('MultiUserSignature.How do you want to open the signed file?')}
        />
      )}

      {initialUsersLoaded ? (
        <div>
          <div className={styles.popupContentWrapper}>
            <MultiUserSignaturePopupHeader
              headerSelectboxState={headerSelectBoxState}
              setHeaderSelectboxState={setHeaderSelectBoxState}
              searchBoxContent={searchBoxContent}
              setSearchBoxContent={setSearchBoxContent}
              signatureUserCount={signatureUserCount}
              noneSignatureUserCount={noneSignatureUserCount}
            />
            <div className={styles.buttonsContainer}>
              <Button className={styles.buttonCancel} onClick={closePopup}>
                {t('Popup.Close')}
              </Button>
              <ButtonWithLoader
                onClick={handleExportPDFForSignatureList}
                type='primary'
                disabled={!initialUsersLoaded}
                isLoading={isPrintLoading}
              >
                {t('Popup.Print')}
              </ButtonWithLoader>
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              type='text'
              placeholder={t('Post.Search by name')}
              value={searchBoxContent}
              onChange={e => setSearchBoxContent(e.target.value)}
            />
          </div>

          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {filteredUsers.map(user => {
                const userId = user.userId || user.id;
                let signatureStatus = t('Post.User has not signed');
                if (user.hasSignature) {
                  signatureStatus = t('Post.User has signed') + ': ' + user.signatureDate;
                }

                return (
                  <div className={styles.item}>
                    <div className={styles.itemAvatar}>
                      <ProfileAvatar image={user} id={userId} withoutLink />
                    </div>

                    {/* eslint-disable-next-line max-len */}
                    <div
                      onClick={() => (user.hasSignature ? setClickedUser(user) : {})}
                      key={user.id}
                      className={styles.itemInfo}
                    >
                      <div className={cn(styles.itemName, 'notranslate')}>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                      {<div className={styles.itemStatus}>{signatureStatus}</div>}
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Popup>
  );
}

MultiUserSignaturePopup.defaultProps = {
  users: [],
};
