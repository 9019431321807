import { useReducer, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import deepcopy from 'deepcopy';

import Storage from 'library/utilities/storage';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { getPosts, updatePost, deletePost, toggleHidePost, reportToSuperAdmin, unsetReportedPost } from 'library/api/posts';
import { reportPost } from 'library/api/groups';
import store from 'main/store/configureStore';
import { changeOrderIfSurvey } from 'library/utilities/surveys';
import {
  checkScrollLoading,
  useLoadingOnScroll,
} from 'library/common/commonHooks/useScrollLoading';
import { isAwoWW } from 'library/api/tenantConfig';

export const initialState = { feeds: [], isLoading: false };

export function reducer(state, action) {
  switch (action.type) {
    case 'startLoading':
      return { ...state, isLoading: true };
    case 'stopLoading':
      return { ...state, isLoading: false };
    case 'addFeeds':
      return { ...state, feeds: state.feeds.concat(action.feeds), isLoading: false };
    case 'addFeed':
      return { ...state, feeds: [action.feed].concat(state.feeds) };
    case 'updateFeedById':
      return {
        ...state,
        feeds: state.feeds.map(feed =>
          feed.id === action.id
            ? {
                ...feed,
                ...action.newValues,
              }
            : feed,
        ),
      };
    case 'deleteFeedById':
    case 'hideFeedById':
      return {
        ...state,
        feeds: state.feeds.filter(feed => feed.id !== action.id),
      };
    case 'removeFeeds':
      return {
        ...state,
        feeds: [],
      };
    default:
      return state;
  }
}

/**
 *
 * @param {Object} options
 * @param {String[]} [options.filteredGroupsOnScroll] - When the feed is refreshed triggered by scrolling, only the groups in this array are fetched.
 * @returns
 */
export default function useFeedsLoading({
  user,
  filters,
  isOnDashboard,
  canLoadFeeds,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  filteredGroupsOnScroll,
  ...loadingOptions
}) {
  const { t } = useTranslation();

  const [{ feeds, isLoading }, dispatch] = useReducer(reducer, initialState);
  const currentPaginationPage = useRef(1);
  const isFeedsLoading = useRef(false);
  const isFeedsEnded = useRef(false);
  const postId = useRef(loadingOptions.postId);
  useEffect(() => {
    postId.current = loadingOptions.postId;
  }, [loadingOptions.postId]);

  const getFeedsPayload = {
    isFeedsLoading,
    isFeedsEnded,
    dispatch,
    filters,
    currentPaginationPage,
    loadingOptions: { ...loadingOptions, isOnDashboard },
    postId,
    isInProfileStream,
    isInPublicPage,
    selectedKitas,
    selectedGroups,
    userId: user.id,
    user: user,
  };

  useEffect(() => {
    if (canLoadFeeds && user?.id) {
      dispatch({ type: 'removeFeeds' });
      currentPaginationPage.current = 1;
      isFeedsEnded[filteredGroupsOnScroll] = false;
      getFeeds(getFeedsPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    canLoadFeeds,
    loadingOptions.postId,
    loadingOptions.searchValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadingOptions.searchGroups && loadingOptions.searchGroups.length,
    user?.id,
  ]);

  useLoadingOnScroll(
    () =>
      checkScrollLoading(
        () => getFeeds({ ...getFeedsPayload, filteredGroups: filteredGroupsOnScroll }),
        1.5,
      ),
    [filteredGroupsOnScroll],
  );

  const addFeed = newFeed => addFeedHandler({ dispatch, newFeed });

  const updateFeedById = async (id, newValues, options = {}) =>
    updateFeedByIdHandler({ options, id, newValues, dispatch, t });

  const deleteFeedById = id => deleteFeedByIdHandler({ id, dispatch, t });

  const hideFeedById = (id, status) => hideFeedByIdHandler({ id, status, dispatch, t });

  const reportToAdmin = (id, groupID, name, kitaId) => onReportToAdmin({ id, groupID, name, kitaId, dispatch, t });

  const unsetReported = (id) => onUnsetReported({ id, dispatch, t });


  let availableFeeds = useMemo(() => {
    if (!user.administrationAccess && !user.superAdminStatus) {
      return feeds.filter(
        e =>
          !e.parentParticipants ||
          e.parentParticipants?.length === 0 ||
          e.parentParticipants.filter(pp => pp?.userId === user.id).length > 0,
      );
    } else return feeds;
  }, [user, feeds]);

  return {
    feeds: (isOnDashboard || isAwoWW) ? availableFeeds : getSortedFeedsByPinned(availableFeeds, filters),
    addFeed,
    isLoading,
    isFeedsEnded,
    updateFeedById,
    deleteFeedById,
    getFeeds,
    hideFeedById,
    newFeeds: filteredGroups => getFeeds({ ...getFeedsPayload, filteredGroups }),
    reportToAdmin,
    unsetReported,
  };
}

export function getSortedFeedsByPinned(feeds, filters = {}) {
  const sortingFilters = filters.sortingFilters || [true];
  const newFeeds = deepcopy(feeds);
  if (sortingFilters[0]) {
    newFeeds.sort(
      (a, b) => b.pinned - a.pinned || b.visibleOrCreatedAt - a.visibleOrCreatedAt,
    );
  } else {
    newFeeds.sort(
      (a, b) => b.pinned - a.pinned || b.visibleOrEditedAt - a.visibleOrEditedAt,
    );
  }

  return newFeeds;
}

export async function getFeeds({
  isFeedsLoading,
  isFeedsEnded,
  dispatch,
  currentPaginationPage,
  loadingOptions,
  postId,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  userId,
  filteredGroups,
  user,
}) {
  const filters = Storage.getItem('currentFilters');
  if (isFeedsLoading.current || isFeedsEnded[filteredGroups]) {
    return;
  }
  isFeedsLoading.current = true;

  dispatch({ type: 'startLoading' });

  await loadPosts({
    filters,
    currentPaginationPage,
    loadingOptions: { ...loadingOptions, postId: postId.current },
    isFeedsEnded,
    dispatch,
    isInProfileStream,
    isInPublicPage,
    selectedKitas,
    selectedGroups,
    userId,
    filteredGroups,
    user,
  });

  isFeedsLoading.current = false;
}

export async function loadPosts({
  filters,
  currentPaginationPage,
  loadingOptions,
  isFeedsEnded,
  dispatch,
  isInProfileStream,
  isInPublicPage,
  selectedKitas,
  selectedGroups,
  userId,
  filteredGroups,
  user,
}) {
  try {
    const result = (await getPosts({
      page: currentPaginationPage.current,
      ...loadingOptions,
      filters,
      isInProfileStream,
      isInPublicPage,
      selectedKitas,
      selectedGroups,
      userId,
      filteredGroups,
      user,
    })).map(changeOrderIfSurvey);

    if (result.length > 0) {
      dispatch({
        type: 'addFeeds',
        feeds: result,
      });
    } else if (result.length < 10) {
      isFeedsEnded[filteredGroups] = true;
    }

    if (result.length === 0) {
      dispatch({
        type: 'stopLoading',
      });
    }
    currentPaginationPage.current += 1;
  } catch (ex) {
    // eslint-disable-next-line
    console.error(ex);
  }
}

export async function updateFeedByIdHandler({ options, id, newValues, dispatch, t }) {
  try {
    if (!options.withoutRequest) {
      const { data } = await updatePost(id, { ...newValues });
      newValues = {
        ...newValues,
        files: data.files,
      };
    }

    dispatch({
      type: 'updateFeedById',
      id,
      newValues: {
        ...newValues,
        editedAt: new Date().getTime(),
      },
    });

    store.dispatch(showBottomNotification(t('BottomNotifications.Saved')));
  } catch (ex) {
    // eslint-disable-next-line
    console.log(ex);
  }
}

export function deleteFeedByIdHandler({ id, dispatch, t }) {
  const userId = store.getState().userReducer.id;
  return deletePost(id, userId)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'deleteFeedById',
          id,
        });
        store.dispatch(
          showBottomNotification(t('BottomNotifications.The content has been deleted')),
        );
        return {
          success: true,
          error: null,
        };
      }
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: res.data,
      };
    })
    .catch(err => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      console.log(err);
      return {
        success: false,
        error: err,
      };
    });
}

export function addFeedHandler({ dispatch, newFeed }) {
  dispatch({
    type: 'addFeed',
    feed: newFeed,
  });
}

export function hideFeedByIdHandler({ id, status, dispatch, t }) {
  return toggleHidePost(id)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: 'hideFeedById',
          id,
        });
        if (status) {
          store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsShow')));
        } else {
          store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsHide')));
        }

        return {
          success: true,
          error: null,
        };
      }
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: res.data,
      };
    })
    .catch(err => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      console.log(err);
      return {
        success: false,
        error: err,
      };
    });
}

export function onReportToAdmin({ id, groupID, name, kitaId, dispatch, t }) {
  return reportToSuperAdmin(id, groupID, name).then(()=> {
    return reportPost(id, groupID, kitaId).then((res) => {
      if (res.status === 200) {
        store.dispatch(showBottomNotification(t('PostTemplatePopup.Reported to Kita-Admin')));
        return {
          success: true,
          error: null,
        };
      }
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: res.data,
      };
    }).catch(err => {
      showBottomNotification(t('BottomNotifications.Something went wrong'), {
        isFail: true,
      });
      return {
        success: false,
        error: err,
      };
    })
  })
}

export function onUnsetReported({ id, dispatch, t }) {
  return unsetReportedPost(id).then(()=> {
    dispatch({
      type: 'updateFeedById',
      id,
      newValues: {
        isReported: false
      },
    });
    store.dispatch(showBottomNotification(t('BottomNotifications.ThePostIsUnset')));
    return {
      success: true,
      error: null,
    };
  })
}
