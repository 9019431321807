import React, { useEffect, useState } from 'react';
import { KitaPanelWrapper } from 'library/common/commonComponents/KitaPanelWrapper';
import { Wrapper } from 'library/common/commonComponents/Wrapper';
import { connect } from 'react-redux';
import styles from './thirdArticle.module.scss';
import { getThirdArticle, saveThirdArticle } from 'library/api/articles';
import useReactRouter from 'use-react-router';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';
import Button from 'library/common/commonComponents/Buttons/Button';
import Loader from 'library/common/commonComponents/Loader';
import awoLogo from 'resources/images/awoww/awoimmerdabeilogo.png';
import { getUserLogoUrl } from 'library/utilities/user';
import { getAwoKitaConnection } from 'library/api/kita';
import { isPublicUser } from 'library/utilities/user';

const ThirdArticle = ({ activeKita, user }) => {
  const { t } = useTranslation();
  const { match } = useReactRouter();
  const kitaId = match.params.id;
  const [profiles, setProfiles] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [originalProfiles, setOriginalProfiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [deletedProfileIds, setDeletedProfileIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [awoConnection, setAWOConnection] = useState(null);

  const visibleFields = user.administrationAccess
    ? ['firstName', 'functionText', 'contactDate']
    : ['firstName', 'functionText'];

  const goToBackPage = () => {
    window.history.go(-1);
  };

  const refresh = () => {
    setIsLoading(true);
    getThirdArticle(kitaId, 3)
      .then(res => {
        setIsLoading(false);
        if (res && res.data) {
          const fetchedProfiles = res.data.map(profile => ({
            id: profile.id,
            firstName: profile.firstName || '',
            functionText: profile.functionText || '',
            contactDate: profile.contactDate || '',
            image: profile.images && profile.images.length > 0 ? profile.images[0].filePath : null,
            imageFile: null,
            imageDeleted: false,
          }));

          setProfiles(fetchedProfiles);
          setOriginalProfiles(fetchedProfiles);
        } else {
          setProfiles([]);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching profiles:', error);
        setProfiles([]);
      });
  };

  useEffect(() => {
    getAwoKitaConnection(kitaId).then(res => {
      setAWOConnection(res.data);
    });
  }, [kitaId]);

  const canEdit =
    ((activeKita.kitaId === awoConnection?.connectedKitaId && user.administrationAccess) ||
      user.superAdminStatus) &&
    !isPublicUser(user);

  useEffect(() => {
    refresh();
  }, [kitaId]);

  const handleInputChange = (index, field, value) => {
    const newProfiles = [...profiles];
    newProfiles[index][field] = value;
    setProfiles(newProfiles);
  };

  const handleImageUpload = (index, file) => {
    const newProfiles = [...profiles];
    newProfiles[index].image = URL.createObjectURL(file);
    newProfiles[index].imageFile = file;
    newProfiles[index].imageDeleted = false;
    setProfiles(newProfiles);
  };

  const handleImageDelete = index => {
    const newProfiles = [...profiles];
    newProfiles[index].image = null;
    newProfiles[index].imageFile = null;
    newProfiles[index].imageDeleted = true;
    setProfiles(newProfiles);
  };

  const isProfileFilled = profile => {
    return profile.firstName || profile.functionText || profile.contactDate;
  };

  const addProfile = () => {
    if (profiles.length < 10) {
      const existingIds = profiles.map(p => p.id);
      const newId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
      setProfiles([
        ...profiles,
        {
          id: null,
          firstName: '',
          functionText: '',
          contactDate: '',
          image: null,
          imageFile: null,
          imageDeleted: false,
        },
      ]);
    }
  };

  const handleEditToggle = () => {
    setOriginalProfiles(profiles);

    if (profiles.length === 0) {
      setProfiles([
        {
          id: null,
          firstName: '',
          functionText: '',
          contactDate: '',
          image: null,
          imageFile: null,
          imageDeleted: false,
        },
      ]);
    }

    setIsEditing(true);
  };

  const handleSave = () => {
    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];
      if (!profile.firstName && !profile.functionText && !profile.contactDate) {
        store.dispatch(
          showBottomNotification(t('Article.Please fill at least one field in each profile.'), {
            isFail: true,
          }),
        );
        return;
      }
    }
    const formData = new FormData();

    profiles.forEach((profile, index) => {
      if (profile.id != null) {
        formData.append(`profiles[${index}].id`, profile.id);
      }
      formData.append(`profiles[${index}].firstName`, profile.firstName || '');
      formData.append(`profiles[${index}].functionText`, profile.functionText || '');
      formData.append(`profiles[${index}].contactDate`, profile.contactDate || '');

      if (profile.imageFile) {
        formData.append(`profiles[${index}].image`, profile.imageFile);
      }

      if (profile.imageDeleted) {
        formData.append(`profiles[${index}].imageDeleted`, 'true');
      }
    });

    deletedProfileIds.forEach(id => {
      formData.append('deletedProfileIds', id);
    });

    setIsLoading(true);
    saveThirdArticle(kitaId, 3, formData)
      .then(response => {
        setIsLoading(false);
        setIsEditing(false);
        setErrorMessage('');
        setDeletedProfileIds([]);
        refresh();
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error saving profiles:', error);
      });
  };

  const handleCancel = () => {
    setProfiles(originalProfiles);
    setDeletedProfileIds([]);
    setIsEditing(false);
    refresh();
  };

  const handleDeleteProfile = index => {
    const profileToDelete = profiles[index];
    const newProfiles = [...profiles];
    newProfiles.splice(index, 1);
    setProfiles(newProfiles);

    if (profileToDelete.id) {
      setDeletedProfileIds([...deletedProfileIds, profileToDelete.id]);
    }
  };

  if (profiles === null) {
    return (
      <KitaPanelWrapper>
        <Wrapper>
          <div>Loading...</div>
        </Wrapper>
      </KitaPanelWrapper>
    );
  }

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className={styles.first_article_heading}>{t('Unser Vorstand')}</div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <>
              <div>
                {isEditing && (
                  <div className={styles.warningMessage}>
                    * Es können bis zu 10 Profile hochgeladen werden
                  </div>
                )}
              </div>
              <div className={styles.profileContainer}>
                {profiles.length === 0 && !isEditing && (
                  <>
                    <p className={styles.noProfile}>Keine Profile gefunden</p>
                    <>
                      <div className={styles.noProfilesMessage}>
                        {canEdit && (
                          <Button className={styles.saveButton} onClick={handleEditToggle}>
                            {t('Erstellen Sie neue Profile')}
                          </Button>
                        )}

                        <Button onClick={() => goToBackPage()} className={styles.cancelButton}>
                          {t('Zurück zur Hauptseite')}
                        </Button>
                      </div>
                    </>
                  </>
                )}
                {profiles.length > 0 &&
                  profiles.map((profile, index) => (
                    <div key={index} className={styles.profileCard}>
                      {isEditing && (
                        <Button
                          className={styles.deleteProfileButton}
                          onClick={() => handleDeleteProfile(index)}
                        >
                          Löschen
                        </Button>
                      )}
                      <div className={styles.profileContent}>
                        <div className={styles.imageSection}>
                          <div className={styles.imageWrapper}>
                            {profile.image ? (
                              <>
                                <div className={styles.profileImageContainer}>
                                  <img
                                    src={profile.image || awoLogo}
                                    alt='Profile'
                                    className={styles.profileImage}
                                  />
                                </div>

                                {isEditing && (
                                  <button
                                    className={styles.deleteImageButton}
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    ✖
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                {isEditing ? (
                                  <label className={styles.imageUploadLabel}>
                                    <input
                                      type='file'
                                      accept='image/*'
                                      onChange={e => handleImageUpload(index, e.target.files[0])}
                                      className={styles.imageInput}
                                    />
                                    <span className={styles.plusIcon}>
                                      <i className='fa fa-upload' />
                                    </span>
                                  </label>
                                ) : (
                                  <div className={styles.profileImageContainer}>
                                    <img src={awoLogo} alt='' className={styles.profileImage} />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className={styles.inputSection}>
                          {visibleFields.map(field => (
                            <div key={field} className={styles.inputGroup}>
                              <label className={styles.inputLabel}>
                                {field === 'firstName'
                                  ? 'Vorname, Name'
                                  : field === 'functionText'
                                  ? 'Funktion'
                                  : 'Kontaktdaten'}
                              </label>
                              {isEditing ? (
                                <input
                                  type='text'
                                  value={profile[field]}
                                  onChange={e => handleInputChange(index, field, e.target.value)}
                                  className={styles.inputField}
                                />
                              ) : (
                                <div className={styles.textField}>{profile[field] || '-'}</div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                {isEditing && profiles.length < 10 && (
                  <div className={styles.plusSection}>
                    <button
                      className={styles.addButton}
                      disabled={
                        profiles.length > 0 && !isProfileFilled(profiles[profiles.length - 1])
                      }
                      onClick={addProfile}
                    >
                      +
                      <br />
                      <p className={styles.moreProfiles}>Weitere Profile hinzufügen</p>
                    </button>
                  </div>
                )}
              </div>
            </>
            <div>
              {isEditing ? (
                <div className={styles.editButtonsContainer}>
                  {canEdit && (
                    <Button className={styles.saveButton} onClick={handleSave}>
                      {t('Speichern')}
                    </Button>
                  )}
                  <Button className={styles.cancelButton} onClick={handleCancel}>
                    {t('Zurück')}
                  </Button>
                </div>
              ) : (
                profiles.length > 0 && (
                  <div className={styles.editButtonContainer}>
                    {canEdit && (
                      <Button className={styles.editButton} onClick={handleEditToggle}>
                        {t('Artikel bearbeiten')}
                      </Button>
                    )}
                    <Button onClick={() => goToBackPage()} className={styles.cancelButton}>
                      {t('Zurück zur Hauptseite')}
                    </Button>
                  </div>
                )
              )}
            </div>
          </>
        )}
      </Wrapper>
    </KitaPanelWrapper>
  );
};

export default connect(state => ({
  activeKita: state.kitaReducer.activeKita,
  user: state.userReducer,
}))(ThirdArticle);
