import React, { useState, useRef, useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import { GroupContext } from 'modules/Group/groupModule';
import { getPathWithGroupId } from 'library/utilities/groups';
import Card from 'library/common/commonComponents/Card';
import EditGalleryPopup from 'library/common/commonComponents/Popups/EditGalleryPopup';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import TextCaret from 'library/common/commonComponents/TextCaret';
import Loader from 'library/common/commonComponents/Loader';
import GalleryAddButton from './galleryFrames/GalleryAddButton';
import GalleryItem from './galleryFrames/GalleryItem';
import GallerySettings from './galleryFrames/GallerySettings';
import GalleryDefaultItem from './galleryFrames/GalleryDefaultItem';
import GalleryDefault from './galleryFrames/GalleryDefault';
import useGalleryLoading from './hooks/useGalleryLoading';
import NonDefaultGallery from './galleryFrames/NonDefaultGallery';
import store from 'main/store/configureStore';

import styles from './gallery.module.scss';
import { getGroupMembersWithoutFilterSorting } from 'library/common/commonActions/groupActions';

export default function Gallery({
  showBottomNotification,
  isProfilePage,
  user,
  activeKita,
  isBazaar,
  profileId,
  isOwner,
}) {
  const { groupInfo, patchGroup } = useContext(GroupContext);
  if (!isOwner && isBazaar && isProfilePage) {
    groupInfo.addModifyGallery = false;
  }

  const { t } = useTranslation();
  const [isCreateGalleryPopupVisible, setIsCreateGalleryPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const {
    isLoading,
    galleries,
    createGallery,
    deleteGallery,
    editGallery,
    deleteImage,
    editImageDescription,
    uploadFilesToGallery,
    progress,
    openedGalleryId,
    setOpenedGalleryId,
    deleteAllImagesFromGallery,
  } = useGalleryLoading(
    groupInfo.groupId,
    groupInfo,
    patchGroup,
    profileId,
    activeKita.kitaId,
    isProfilePage,
    isBazaar,
  );
  const [isSettingsOpened, setisSettingsOpened] = useState(false);
  const [isDefaultGalleryOpened, setIsDefaultGalleryOpened] = useState(false);
  const { location, history, match } = useReactRouter();
  useEffect(
    parseUrlAfterLoading({
      isLoading,
      location,
      groupInfo,
      setisSettingsOpened,
      galleries,
      setOpenedGalleryId,
      setIsDefaultGalleryOpened,
    }),
    [isLoading],
  );

  useEffect(changeRoute({ isLoading, match, openedGalleryId, history, isBazaar }), [
    isLoading,
    openedGalleryId,
  ]);

  const filesInputRef = useRef(null);

  const activeGallery = galleries.find(({ id }) => id === openedGalleryId);

  const triggerFileInput = () => {
    filesInputRef.current.click();
  };

  const checkIfGroupMember = () => {
    getGroupMembersWithoutFilterSorting(groupInfo.groupId).then((res) => {
      const response = res.data.content
      const foundMember = response.find(obj => obj.user.id === user.id)
      if (foundMember) {
        setIsCreateGalleryPopupVisible(true)
      } else {
          showBottomNotification(t('Gallery.Gallery can only be created by a group member'), { isFail: true })
      }
    });
  }

  const dropdownOptions = getDropdownOptions({
    gallery: activeGallery,
    t,
    user,
    setIsCreateGalleryPopupVisible,
    setIsDeletePopupVisible,
    triggerFileInput,
    setisSettingsOpened,
    isProfilePage,
    isBazaar,
    groupInfo,
  });

  return isLoading ? (
    <Loader />
  ) : isDefaultGalleryOpened ? (
    <GalleryDefault
      groupInfo={groupInfo}
      goBack={() => setIsDefaultGalleryOpened(false)}
      isProfilePage={isProfilePage}
      user={user}
      activeKita={activeKita}
      deleteAllImagesFromGallery={deleteAllImagesFromGallery}
      deleteImage={deleteImage}
      galleries={galleries}
    />
  ) : isSettingsOpened ? (
    <GallerySettings
      galleries={galleries}
      groupInfo={groupInfo}
      goBack={() => setisSettingsOpened(false)}
      showBottomNotification={showBottomNotification}
      patchGroup={patchGroup}
    />
  ) : activeGallery ? (
    <NonDefaultGallery
      isCreateGalleryPopupVisible={isCreateGalleryPopupVisible}
      setIsCreateGalleryPopupVisible={setIsCreateGalleryPopupVisible}
      editGallery={editGallery}
      activeGallery={activeGallery}
      groupInfo={groupInfo}
      galleries={galleries}
      isDeletePopupVisible={isDeletePopupVisible}
      setIsDeletePopupVisible={setIsDeletePopupVisible}
      deleteGallery={deleteGallery}
      setOpenedGalleryId={setOpenedGalleryId}
      dropdownOptions={dropdownOptions}
      progress={progress}
      filesInputRef={filesInputRef}
      deleteImage={deleteImage}
      editImageDescription={editImageDescription}
      uploadFilesToGallery={uploadFilesToGallery}
      isProfilePage={isProfilePage}
      user={user}
      activeKita={activeKita}
      isBazaar={isBazaar}
      deleteAllImagesFromGallery={deleteAllImagesFromGallery}
    />
  ) : (
    <>
      {isCreateGalleryPopupVisible && (
        <EditGalleryPopup
          title={
            <Trans i18nKey={isBazaar ? 'Bazaar.Add new category' : 'Gallery.Add new gallery'} />
          }
          isOpened={isCreateGalleryPopupVisible}
          closePopup={() => setIsCreateGalleryPopupVisible(false)}
          createGallery={createGallery}
          galleries={galleries}
          group={groupInfo}
        />
      )}
      <Card
        title={<Trans i18nKey={isBazaar ? 'Bazaar.Category list' : 'Gallery.List of galleries'} />}
        className={styles.container}
      >
        {groupInfo.addModifyGallery && (
          <MoreBtn
            dropdownOptions={dropdownOptions}
            faIcon='fa-cog'
            className={styles.headerMoreBtn}
          >
            <TextCaret color='#555' />
          </MoreBtn>
        )}
        <div className={styles.galleryContainer}>
          {(groupInfo.addModifyGallery || user.administrationAccess) && (
            <GalleryAddButton
              text={
                isBazaar ? t('Bazaar.Add new category') : t('Gallery.Click here to add new Gallery')
              }
              onClick={() => checkIfGroupMember()}
            />
          )}
          {!isBazaar && <GalleryDefaultItem onClick={() => setIsDefaultGalleryOpened(true)} />}
          {galleries.map(subGallery => (
            <GalleryItem
              key={subGallery.id}
              gallery={subGallery}
              deleteGallery={deleteGallery}
              editGallery={editGallery}
              onClick={() => setOpenedGalleryId(subGallery.id)}
              galleries={galleries}
              groupInfo={groupInfo}
              user={user}
            />
          ))}
        </div>
      </Card>
    </>
  );
}

export function getDropdownOptions({
  gallery,
  t,
  setIsCreateGalleryPopupVisible,
  setIsDeletePopupVisible,
  triggerFileInput,
  setisSettingsOpened,
  isProfilePage,
  isBazaar,
  user,
  groupInfo,
}) {

  const options = gallery
    ? [
      {
        faIcon: 'fa-cloud-upload',
        title: t('Gallery.Dropdown.Upload'),
        onClick: triggerFileInput,
      },
      {
        faIcon: 'fa-edit',
        title: t('Gallery.Dropdown.Edit gallery'),
        onClick: () => setIsCreateGalleryPopupVisible(true),
      },
      {
        faIcon: 'fa-trash',
        title: t('Gallery.Dropdown.Delete gallery'),
        onClick: () => setIsDeletePopupVisible(true),
      },
    ]
    : [
      // STR-4427
      /* {
        faIcon: 'fa-link',
        title: t('test'),
        onClick: () => setisSettingsOpened(true),
        isHidden: isProfilePage,
      }, */
    ];

  if ((user.administrationAccess || groupInfo.addModifyGallery) && !gallery) {
    options.push({
      faIcon: 'fa-plus',
      title: isBazaar ? t('Bazaar.Add new category') : t('Gallery.Dropdown.Add gallery'),
      onClick: () => setIsCreateGalleryPopupVisible(true),
    });
  }

  return options;
}

export function parseUrlAfterLoading({
  isLoading,
  location,
  groupInfo,
  setisSettingsOpened,
  galleries,
  setOpenedGalleryId,
  setIsDefaultGalleryOpened,
}) {
  return () => {
    if (!isLoading) {
      const galleryId = location.search.slice(1);
      if (galleryId === 'settings') {
        if (groupInfo.addModifyGallery) {
          setisSettingsOpened(true);
        }
      } else if (galleryId === 'default') {
        setIsDefaultGalleryOpened(true);
      } else if (galleries.find(({ id }) => id === Number(galleryId))) {
        setOpenedGalleryId(Number(galleryId));
      }
    }
  };
}

export function changeRoute({ isLoading, match, openedGalleryId, history, isBazaar }) {
  return () => {
    if (!isLoading) {
      const url = getPathWithGroupId(match.path);
      const domain = isBazaar ? 'bazaar' : 'gallery';
      if (openedGalleryId) {
        history.push(`${url}/${domain}?${openedGalleryId}`);
      } else {
        history.push(`${url}/${domain}`);
      }
    }
  };
}
