import { useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { getScimConfigPublic, isScimEnabled } from 'library/api/kita';

const port = window.location.port ? `:${window.location.port}` : '';
const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;

export const ACCESS_SCOPES = ['User.Read'];

const getMsalConfig = clientId => {
  return {
    auth: {
      clientId,
      redirectUri,
    },
    cache: {
      storeAuthStateInCookie: true,
      cacheLocation: 'localStorage',
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        logLevel: 0,
      },
    },
  };
};

const createMsalInstance = async () => {
  try {
    const isScimSettingEnabled = await (await isScimEnabled()).data;
    if (!isScimSettingEnabled) {
      return null;
    }
    const res = await getScimConfigPublic();
    if (!res.data.active || !res.data.clientId) {
      return null;
    }
    const clientId = res.data.clientId;
    const msalConfig = getMsalConfig(clientId);

    return new PublicClientApplication(msalConfig);
  } catch (error) {
    console.error('Failed to fetch client ID:', error);
    throw error;
  }
};

export const useMsalInstance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [msalInstance, setMsalInstance] = useState(null);

  useEffect(() => {
    const initialMsal = async () => {
      try {
        setIsLoading(true);
        const instance = await createMsalInstance();
        setMsalInstance(instance);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to initialize MSAL:', error);
        setIsLoading(false);
      }
    };

    initialMsal();
  }, []);

  return { isLoading, msalInstance };
};
