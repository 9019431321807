import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { isImage } from 'library/utilities/files';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import Loader from 'library/common/commonComponents/Loader';
import EditorWithButton from 'library/common/commonComponents/Inputs/EditorWithButton';
import Tooltip from 'library/common/commonComponents/Tooltip';
import TooltipWithChildren from 'library/common/commonComponents/TooltipWithChildren';
import { timeSince, getUpdatedTime } from 'library/utilities/date';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import MessageWithFiles from 'library/common/commonComponents/MessageWithFiles';
import useEditing from 'library/common/commonHooks/useEditing';
import useLikes from 'library/common/commonHooks/useLikes';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import ContentAfterMount from 'library/common/commonComponents/ContentAfterMount';

import styles from './comment.module.scss';
import { getCuttedString } from 'library/utilities/user';

export default function Comment({
  user,
  name,
  createdAt,
  editedAt,
  text,
  userId,
  id,
  updateCommentById,
  likes,
  onDelete,
  groupId,
  files,
  postId,
  firstName,
  lastName,
  activeKita,
  isTranslationAllowed,
  watsonLanguage,
  usersTitle,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled = true,
  approveStatus,
  approveCommentById,
  rejectCommentById,
  isGroupAdmin,
  isPrivate,
  ...avatar
}) {
  const { t } = useTranslation();
  const date = createdAt !== editedAt ? editedAt : createdAt;
  const { langCode } = user;

  let allImages;
  if (typeof files === 'undefined' || files === null) {
    allImages = null;
  } else {
    allImages = files.filter(file => isImage(file.fileId));
  }

  let tempCommentLanguage;
  if (
    typeof watsonLanguage === 'undefined' ||
    watsonLanguage == null ||
    watsonLanguage === 'null'
  ) {
    tempCommentLanguage = 'de';
  } else {
    tempCommentLanguage = watsonLanguage;
  }
  if (user.currentWatsonLang === tempCommentLanguage) {
    isTranslationAllowed = false;
  }

  const [deletePopupIsOpened, setDeletePopupIsOpened] = useState(false);

  const [privateComment, setPrivateComment] = useState(isPrivate ?? false);

  const updateComment = async (commentId, payload) => {
    const newFiles = payload.files;
    const newPayload = {
      ...payload,
      files: newFiles.filter(newFile => !newFile.id),
      postId,
    };

    const deletedFileIds = files
      ? files
          .reduce((result, file) => {
            const isNotDeleted = newFiles.find(newFile => file.id === (newFile.id || ''));
            return (result = !isNotDeleted ? result.concat(file.id) : result);
          }, [])
          .join(',')
      : [];

    if (deletedFileIds.length) {
      newPayload.deletedFileIds = deletedFileIds;
    }
    await updateCommentById(commentId, newPayload, privateComment);
  };

  const { isEditing, setIsEditing, isSubmiting, setIsSubmiting, ...editorProps } = useEditing({
    text,
    updateItemById: updateComment,
    id,
    placeholder: t('Editor.Edit your comment'),
    groupId,
  });

  const { LikeButton, ...likeButtonProps } = useLikes({
    likes,
    user,
    id,
    target: 'comment',
  });

  let dropdownOptions = userId === user.id && [
    {
      faIcon: 'fa-pencil',
      title: isEditing ? t('Post.Cancel edit') : t('Post.Edit'),
      onClick: () => {
        setIsEditing(!isEditing);
      },
    },
    {
      faIcon: 'fa-trash-o',
      title: t('Post.Delete'),
      onClick: () => {
        setDeletePopupIsOpened(true);
      },
    },
  ];

  if (approveStatus === 'PENDING' && isGroupAdmin) {
    if (!Array.isArray(dropdownOptions) || !dropdownOptions) {
      dropdownOptions = [];
    }
    dropdownOptions.unshift({
      faIcon: 'fa-check',
      title: t('Post.ApproveComment'),
      onClick: () => {
        approveCommentById();
      },
    });
    dropdownOptions.unshift({
      faIcon: 'fa-ban',
      title: t('Post.RejectComment'),
      onClick: () => {
        rejectCommentById();
      },
    });
  }

  const handleDeleteBtnClick = async () => {
    setIsSubmiting(true);
    await onDelete(id);
    setIsSubmiting(false);
    setDeletePopupIsOpened(false);
  };

  return (
    <div className={styles.comment}>
      <DeletePopup
        isOpened={deletePopupIsOpened}
        closePopup={() => setDeletePopupIsOpened(false)}
        onDeleteBtnClick={handleDeleteBtnClick}
        headerText={<Trans i18nKey='Post.Confirm comment delition.Title' />}
        bodyText={t('Post.Confirm comment delition.Text')}
        isSubmiting={isSubmiting}
      />

      {!activeKita || (activeKita && !activeKita.virtual) ? (
        <div className={styles.moreBtn}>
          {isSubmiting ? (
            <Loader dotColor='#7a7a7a' dotSize='8px' className={styles.submitting} />
          ) : (
            <MoreBtn dropdownOptions={dropdownOptions} />
          )}
        </div>
      ) : null}

      <div className={styles.avatar}>
        <ContentAfterMount>
          <ProfileAvatar image={avatar} id={userId} />
        </ContentAfterMount>
      </div>
      <div className={styles.commentBody}>
        <div className={styles.name}>
          <TooltipWithChildren userId={userId}>
            <span className='notranslate'>{name || `${firstName} ${lastName}`}</span>
            &nbsp;
            {((usersTitle && usersTitle !== '') ||
              (user.title && user.title !== '' && user?.id == id)) &&
            (window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ||
              window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') ||
              window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') ||
              window.location.hostname.toLowerCase().includes('dev2.safe2connect.org'))
              ? `(${getCuttedString(
                  usersTitle ? usersTitle : user?.id == id ? user?.title : '',
                  80,
                )})`
              : ''}
          </TooltipWithChildren>
          <div className={styles.date}>
            {timeSince({ date, langCode })}
            {createdAt !== editedAt && (
              <>
                <span className={styles.updatedSeparator}>·</span>
                <Tooltip text={getUpdatedTime(editedAt, langCode)} isVisibleCondition>
                  <span className={styles.updated}>Updated</span>
                </Tooltip>
              </>
            )}
            {privateComment && (
              <>
                <span className={styles.updatedSeparator}>·</span>
                <span className={styles.updated}>{t('Comment.Private')}</span>
              </>
            )}
          </div>
        </div>
        {isEditing ? (
          <EditorWithButton
            className={styles.editor}
            btnText={t('Post.Save')}
            messgeFiles={files}
            isSubmiting={isSubmiting}
            user={user}
            privateComment={privateComment}
            setPrivateComment={setPrivateComment}
            {...editorProps}
          />
        ) : (
          <div className={styles.commentTextContainer}>
            {approveStatus === 'REJECTED' && <i className='fa fa-ban' />}
            {approveStatus === 'PENDING' && <i className='fa fa-lock' />}
            <MessageWithFiles
              files={files}
              allImages={allImages}
              text={text}
              entityId={id}
              entityType='comment'
              isTranslationAllowed={isTranslationAllowed}
            />
          </div>
        )}
        {isLikeFeatureAvailableInGroup && likeFeatureEnabled && <LikeButton {...likeButtonProps} />}
        <div className={styles.hr} />
      </div>
    </div>
  );
}
