import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getConfig } from 'library/api/kita';
import { isAwoWW } from 'library/api/tenantConfig';
import {
  getUsersTabCount,
  getPendingApprovals,
  getPendingKidsForCurrentKita,
  getAllPendingApprovals
} from 'library/api/user';
import Tabs from 'library/common/commonComponents/Tabs';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import UsersFrameList from './usersFrameFrames/UsersFrameList/UsersFrameListContainer';
import UsersFrameChildren from './usersFrameFrames/UsersFrameChildren/UsersFrameChildrenContainer';
import UsersFrameAuthorizedToPickUp from './usersFrameFrames/UsersFrameAuthorizedToPickUp/UserFrameAuthorizedToPickUpContainer';
import UsersFrameRegistration from './usersFrameFrames/UsersFrameRegistration/UsersFrameRegistrationContainer';
import UsersFrameApproval from './usersFrameFrames/UsersFrameApproval/UserFrameApprovalContainer';
import UsersFrameChildOverview from './usersFrameFrames/UsersFrameChildOverview/UsersFrameChildOverviewContainer';
import UsersFrameEmployeeWrapper from './usersFrameFrames/UsersFrameEmployeeWrapper';
import UsersFrameKitaAdminsWrapper from './usersFrameFrames/UsersFrameKitaAdminsWrapper/UsersFrameKitaAdminsWrapper';
import UsersFrameAwoMembersWrapper from './usersFrameFrames/UsersFrameAwoMembersWrapper/UsersFrameAwoMembersWrapper';

import styles from './usersFrame.module.scss';

export function UsersFrame({ history, match, location, activeKita, goToKita, user }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [isAllUserLoading, setIsAllUserLoading] = useState(true);
  const { t } = useTranslation();
  const [tabCount, setTabCount] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [memberOptionVisible, setMemberOptionVisible] = useState(false);

  //const { ...tableProps } = useUsersRegistrationList({ isPending: true, activeKita });

  useSiteTitle('AdministrationUsers');

  const isSafeToConnectType = (activeKita.kitaTypeTranslations || []).includes('Safe2Connect');

  useEffect(() => {
    if (user.administrationAccess) {
      loadUsers();
      loadCount(activeKita.kitaId);
      if (isAwoWW()) {
        loadAllUsers();
      }
    }
    getConfig().then((res) => {
      const kitaConfig = res.data;
      setMemberOptionVisible(kitaConfig.memberOptionVisible);
    })
  }, []);

  useEffect(() => {
    if (goToKita.kitaId) {
      setTimeout(() => {
        loadUsers();
        loadCount(goToKita.kitaId);
        if (isAwoWW()) {
          loadAllUsers();
        }
      }, 1000);
    }
  }, [goToKita.kitaId]);

  const loadUsers = () =>
    loadUsersHandler({
      setUsers,
      setIsLoading,
    });

  const loadAllUsers = () => {
    loadAllUsersHandler({
      setAllUsers,
      setIsAllUserLoading,
    });
  }

  const loadCount = async (kitaId) => {
    const pendingKidsData = await getPendingKidsForCurrentKita();
    setPendingCount(pendingKidsData.data.length);
    getUsersTabCount(kitaId).then(data => {
      setTabCount(data);
    });
  };

  const [editingProfile, setEditingProfile] = useState(null);

  let tabs = [
    {
      title: `${t('Administration.Users')} (${tabCount.data ? tabCount.data[0] : 0})`,
      component: <UsersFrameList location={location} history={history} loggedInUser={user} />,
      path: 'list',
    },
  ];

  if (user.administrationAccess) {
    tabs = [
      ...tabs,
      ...[
        !isSafeToConnectType && {
          title: `${t('Administration.ChildOverview')} (${tabCount.data ? tabCount.data[1] : 0})`,
          component: <UsersFrameChildOverview activeKita={activeKita} />,
          path: 'child-overview',
        },
        {
          title: `${t('Administration.Pending registrations')}  (${
            tabCount.data ? tabCount.data[2] : 0
          })`,
          component: <UsersFrameRegistration location={location} history={history} />,
          path: 'pending',
        },
        {
          title: `${t('Administration.Pending Approvals')} (${users.length})`,
          component: <UsersFrameApproval users={users} isLoading={isLoading} setUsers={setUsers} />,
          path: 'waiting-for-approval',
        },
        !isSafeToConnectType && {
          title: `${t('Administration.Pending Child Approvals')} (${pendingCount})`,
          component: <UsersFrameChildren activeKita={activeKita} />,
          path: 'waiting-for-kid-approval',
        },
        {
          title: `${t('Administration.Pending authorized to pick up approval')}`,
          component: <UsersFrameAuthorizedToPickUp activeKita={activeKita} />,
          path: 'waiting-for-authorized-approval',
        },
        {
          title: `${t('Administration.Employee')} (${tabCount.data ? tabCount.data[3] : 0})`,
          component: <UsersFrameEmployeeWrapper />,
          path: 'employee-wrapper',
        },
        {
          title: `${t('Administration.Kita Admins')}`,
          component: (
            <UsersFrameKitaAdminsWrapper
              activeKita={activeKita}
              editingProfile={editingProfile}
              setEditingProfile={setEditingProfile}
              user={user}
            />
          ),
          path: 'kita-admins-wrapper',
        },
      ],
    ];
    if (isAwoWW()) {
      tabs.push({
        title: `${t('Administration.AllPendingApprovals')} (${allUsers.length})`,
        component: <UsersFrameApproval users={allUsers} isLoading={isAllUserLoading} setUsers={setAllUsers} isUseForAllKita={true} />,
        path: 'all-waiting-for-approval',
      },
      {
        title: `${t('Administration.AllKitaAdmin')} (${tabCount.data ? tabCount.data[4] : 0})`,
        component: <UsersFrameList location={location} history={history} loggedInUser={user} showOnlyAdmin={true}/>,
        path: 'all-kita-admin',
      })
    }
  }

  // Hide kita related tabs for awo ww
  if (isAwoWW()) {
    tabs = tabs.filter(
      el =>
        el.path !== 'child-overview' &&
        el.path !== 'waiting-for-kid-approval' &&
        el.path !== 'waiting-for-authorized-approval',
    );
    if (memberOptionVisible) {
      tabs.push({
        title: `${t('Administration.Kita Awo Members')}`,
        component: (
          <UsersFrameAwoMembersWrapper
            activeKita={activeKita}
            editingProfile={editingProfile}
            setEditingProfile={setEditingProfile}
            user={user}
          />
        ),
        path: 'kita-awo-members-wrapper',
      });
    } else {
      tabs = tabs.filter(
        el =>
          el.path !== 'employee-wrapper'
      );
    }
  }

  if (window.location.hostname.toLowerCase().includes('ivs.safe2connect.org')) {
    tabs = tabs.filter(el => el.path !== 'waiting-for-kid-approval');
  }

  if (isSafeToConnectType) {
    tabs = tabs.filter(
      el => el.path !== 'waiting-for-kid-approval' && el.path !== 'child-overview',
    );
  }

  const onTabChange = path => {
    history.push(`/administration/users/${path}`);
  };

  return (
    <div className={styles.container}>
      <Tabs
        tabs={tabs}
        currentTab={match.params.tab ? match.params.tab : tabs[0].path}
        onChange={onTabChange}
      />
    </div>
  );
}

export async function loadUsersHandler({ setUsers, setIsLoading }) {
  setIsLoading(true);
  const { data } = await getPendingApprovals();
  setUsers(data);
  setIsLoading(false);
}

export async function loadAllUsersHandler({ setAllUsers, setIsAllUserLoading }) {
  setIsAllUserLoading(true);
  const { data } = await getAllPendingApprovals();
  setAllUsers(data);
  setIsAllUserLoading(false);
}

export default connect(state => ({
  activeKita: state.kitaReducer.activeKita,
  goToKita: state.kitaReducer.goToKita,
}))(UsersFrame);
